import { AbstractControlOptions, FormArray, FormControl, FormGroup } from '@angular/forms';
import { AbstractFormControl, InlineFormGroup } from '../service/base.service';
import { ArrayPaths, ExtractType, Leaves, ObjectPaths } from './form-types.interface';

export class NxFormGroup<FormControlType> extends FormGroup {
  constructor(controls: AbstractFormControl<FormControlType>, options?: AbstractControlOptions | null) {
    super(controls, options);
  }

  /**
   * Dont allow the default setValue
   * the default setValue only accepts known keys
   * PatchValue allows sub and super sets
   *
   * also set values deep in the form for arrays
   *
   * @param value value to set
   * @param options options
   * @returns
   */
  override setValue(value: { [key: string]: any }, options?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    // patch instead of set value
    super.patchValue(value, options);

    // set values deep in the form for arrays
    const arrayControls = this.getAllChildArrays(this);
    for (const arrayControl of arrayControls) {
      // get the value at the path
      const pathValue = this.getValueAtPath(value, arrayControl.path);

      this.getChildArray(arrayControl.path).clear();
      for (const item of pathValue) {
        const newControl = new FormControl();
        newControl.patchValue(item);
        this.getChildArray(arrayControl.path as any).push(newControl);
      }
    }
  }

  //#region Form Control
  /**
   * Set a value of a child control by path
   *
   * @param path path to set the value
   * @param value value to set
   */
  setChildValue<K extends Leaves<FormControlType>>(path: K, value: ExtractType<FormControlType, K>) {
    this.getControlByPath(path).setValue(value as any);
  }

  /**
   * Get a value of a child control by path
   *
   * @param path path to get the value
   * @returns
   */
  getChildValue<K extends Leaves<FormControlType>>(path: K): ExtractType<FormControlType, K> {
    return this.getControlByPath(path).value;
  }

  /**
   * Set a control of a child group by path
   *
   * @param path path to set the value
   * @param control control to set
   */
  setChildControl<K extends Leaves<FormControlType>>(
    path: K,
    control: AbstractFormControl<ExtractType<FormControlType, K>>
  ) {
    let currentControl = this.getControlByPath(path);
    currentControl = control as FormControl;
  }

  /**
   * Get a control of a child control by path
   *
   * @param path path to get the control
   * @returns
   */
  getChildControl<K extends Leaves<FormControlType>>(path: K): AbstractFormControl<ExtractType<FormControlType, K>> {
    let currentControl = this.getControlByPath(path);
    return currentControl as AbstractFormControl<ExtractType<FormControlType, K>>;
  }
  //#endregion Form Control
  //#region Form Group
  /**
   * Set a group of a child form group by path
   *
   * @param path path to set the value
   * @param control
   */
  setChildGroup<K extends ObjectPaths<FormControlType>>(
    path: K,
    control: InlineFormGroup<ExtractType<FormControlType, K>>
  ) {
    let currentControl = this.getGroupByPath(path);
    currentControl = control;
  }

  /**
   * Returns a FormGroup by path
   *
   * @param path If this is never it means there are no Objects in the Form
   * @returns
   */
  getChildGroup<K extends ObjectPaths<FormControlType>>(path: K) {
    let currentControl = this.getGroupByPath(path);
    return currentControl as InlineFormGroup<ExtractType<FormControlType, K>>;
  }
  //#endregion Form Group
  //#region Form Array
  /**
   * Returns a FormArray by path
   *
   * @param path If this is never it means there are no FromArrays in the Form
   * @returns
   */
  getChildArray<K extends ArrayPaths<FormControlType>>(path: K) {
    return this.getArrayByPath(path);
  }

  /**
   * Adds the given form group to a form array
   *
   * @param path path to add the group to
   * @param control
   */
  addChildElementToArray<K extends ArrayPaths<FormControlType>>(
    path: K,
    control: NxFormGroup<NonNullable<ExtractType<FormControlType, K>>>
  ) {
    this.getChildArray<K>(path).push(control);
  }

  /**
   * removes a form group from a form array by id
   *
   * @param path path to add the group to
   * @param id id to remove
   * @param key field to check for the id. default is 'id'
   * @returns
   */
  removeChildElementByID<K extends ArrayPaths<FormControlType>>(path: K, id: string, key = 'id') {
    const control = this.getChildArray<K>(path);
    const index = control.value.findIndex((value: any) => value[key] === id);

    if (index < 0) return;
    control.removeAt(index);
  }
  //#endregion Form Array
  //#region Path
  /**
   * Get an abstract control by path
   *
   * @param path path to get the control
   * @returns
   */
  private getControlByPath(path: string): FormControl<any> {
    const pathArray = path.split('.');
    let currentControl = this.controls[pathArray[0]] as FormGroup;

    if (!currentControl) {
      throw new Error(`Control with path ${pathArray[0]} not found`);
    }

    for (let i = 1; i < pathArray.length; i++) {
      if (!currentControl.controls) {
        throw new Error(`Control with path ${pathArray[0]} is not a form group`);
      }

      currentControl = currentControl.controls[pathArray[i]] as FormGroup;
    }

    return currentControl as any;
  }

  /**
   * Get a form group by path
   *
   * @param path path to get the control
   * @returns
   */
  private getGroupByPath(path: string): FormGroup {
    return this.getControlByPath(path) as any;
  }

  /**
   * Get a form array by path
   *
   * @param path path to get the control
   * @returns
   */
  private getArrayByPath(path: string): FormArray {
    return this.getControlByPath(path) as any;
  }
  //#endregion Path
  //#region Private Utils

  /**
   * function for getting a value of an object at a path
   * Used in setValue to set values deep in the form for arrays
   */
  private getValueAtPath(obj: any, path: string): any[] {
    const keys = path.split('.');

    let current = obj;
    for (const key of keys) {
      if (current == null || !(key in current)) {
        return [];
      }
      current = current[key];
    }

    return current;
  }

  /**
   * Get all child array in the given form group
   *
   * recursive function to get all child arrays in the form group
   *
   * @param group
   * @param controls
   * @param path
   * @returns
   */
  private getAllChildArrays(
    group: FormGroup<any>,
    controls: { path: ArrayPaths<FormControlType>; control: FormArray }[] = [],
    path = ''
  ): { path: ArrayPaths<FormControlType>; control: FormArray }[] {
    for (const [key, control] of Object.entries(group.controls)) {
      // Build the path to later get the value from the object
      const currentPath = [path, key].filter(Boolean).join('.') as ArrayPaths<FormControlType>;

      if (control instanceof FormArray) {
        controls.push({ path: currentPath, control });
      }

      if (control instanceof FormGroup) {
        controls = this.getAllChildArrays(control, controls, currentPath);
      }
    }

    return controls;
  }
}
