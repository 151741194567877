import { MatTableDataSource } from '@angular/material/table';
import { OffsetPageInfo, OffsetPaging, TxApi } from '@tx/api';
import { MutationResult, QueryRef } from 'apollo-angular';
import { BehaviorSubject, catchError, firstValueFrom, map, Observable, tap } from 'rxjs';
import { cloneDeep } from '@apollo/client/utilities';
import { NotificationService } from '@tx/ui';
import { MutationOptionsAlone } from 'apollo-angular/types';
import { SortDirection, TxAdminApi } from '@tx-admin-api';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { AbstractControl, AbstractControlOptions, FormArray, FormBuilder, FormGroup, ɵElement } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NxFormGroup } from '../form/nx-formgroup';

/**
 * Types used for form controls
 * converts a type to a type where all fields are form controls
 */
export type SimpleFormControls<T> = {
  [Key in keyof T]: AbstractControl<T[Key]>;
};

/**
 * Types used for inline form groups
 * Used for nested form groups
 */
export type InlineFormGroup<T> = FormGroup<SimpleFormControls<T>>;
type FormGroupArray<T> = FormArray<InlineFormGroup<T>>;

/**
 * Unwraps objects from a type
 * Converts {a: string, b: number} to string | number
 * and {a: {b: string}} to {b: string}
 */
type Unwrap<T> = T[keyof T];

/**
 * Unwraps a key from a type
 * Converts {a: string, b: number} and 'b' to number
 * and {a: {b: string}} and 'a' to {b: string}
 */
type UnwrapByKey<T, K extends keyof T> = T[K];

/**
 * Maybe type
 * Converts a type to a nullable and optional type
 */
type Maybe<T> = T | null | undefined;

/**
 * Converts a type to an array of form controls
 * Because we want to convert
 * {t: AnyObjectType[]} to {t: FormControl<AnyObjectType>[]} and not {t: FormControl<AnyObjectType[]>}
 */
type AbstractControlArray<T> = AbstractControl<T>[];

/**
 * Converts a type to a type where all fields are form controls
 * if the field is an array of objects it converts it to an AbstractControlArray
 */
export type AbstractFormControl<T> = {
  [Key in keyof T]: T[Key] extends Maybe<Array<object>>
    ? FormGroupArray<NonNullable<T[Key]>[0]>
    : T[Key] extends Array<object>
    ? AbstractControlArray<T[Key][0]>
    : T[Key] extends Maybe<object>
    ? InlineFormGroup<NonNullable<T[Key]>>
    : AbstractControl<T[Key]>;
};

/**
 * Nullable helper type
 * converts all fields to nullable
 */
type Nullable<T> = {
  [Key in keyof T]: T[Key] | null;
};

/**
 * Deep partial helper type
 * converts all fields recursive to partial
 */
type DeepPartial<T> = {
  [Key in keyof T]?: T[Key] extends Maybe<object> ? DeepPartial<T[Key]> : Partial<T[Key]>;
};

// https://stackoverflow.com/questions/66044717/typescript-infer-type-of-abstract-methods-implementation
interface BaseServiceInterface {
  // Query Functions
  queryFunction: (...args: any[]) => QueryRef<any, any>;
  queryOneFunction: ((...args: any[]) => QueryRef<any, any>) | undefined;

  // Create functions
  createOneFunction: ((input: { input: any }) => Observable<MutationResult<any>>) | undefined;

  // Update Functions
  updateOneFunction: ((input: { input: any }) => Observable<MutationResult<any>>) | undefined;
  updateManyFunction:
    | ((input: { input: { ids: string[]; update: any } }) => Observable<MutationResult<any>>)
    | undefined;

  // Delete Functions
  deleteOneFunction:
    | ((input: { input: { id: string } }, options?: MutationOptionsAlone<any, any>) => Observable<MutationResult<any>>)
    | undefined;
}

export interface NotificationMessages {
  successMessage: string;
  errorMessage: string;
}

/**
 * Base service for all services
 *
 * Requires the following functions to be implemented:
 * - queryFunction
 * - updateOneFunction
 * - updateManyFunction
 * - deleteOneFunction
 * - createOneFunction
 *
 * You need to bind the functions to the txApi.
 * You can set functions to undefined if you dont need them
 *
 * @param DataType The data type of the query result (type must allways be an array)
 * @param T The service interface (put the class itself here)
 *
 * @example
 *
 * export class OpencloudProjectService extends BaseService<
 * OpencloudProjectsQuery['opencloudProjects']['edges'][0]['node'][],
 * OpencloudProjectService> {
 *    queryFunction = this.txApi.opencloudProjectsWatch.bind(this.txApi);
 * }
 */
export abstract class BaseService<
  DataType extends Array<any>,
  T extends BaseServiceInterface,
  API extends TxApi | TxAdminApi
> {
  // The query reference
  private dataQuery$!: ReturnType<T['queryFunction']>;

  // Query data result
  private data$ = new BehaviorSubject<DataType | undefined>(undefined);

  private _dataSource = new MatTableDataSource<DataType[0]>([]);
  public get dataSource() {
    return this._dataSource;
  }

  private totalCount$ = new BehaviorSubject<number | undefined>(undefined);

  private pageInfo$ = new BehaviorSubject<OffsetPageInfo | undefined>(undefined);

  // Needs to implemented for search
  protected SEARCH_FIELDS: string[] = [];

  // Filter
  private currentFilter:
    | (typeof this.queryFunction extends (variables: { filter: infer F }, ...args: any[]) => QueryRef<any, any>
        ? F
        : never)
    | undefined = undefined;

  /**
   * Fields that can be sorted.
   * you can get these by using Object.values(Enum) where the enum is imported
   * from the generated types
   */
  abstract sortableFields: string[];

  // Defaults for sort / filter override in child if needed
  protected DEFAULT_SORT_DIRECTION: 'Asc' | 'Desc' = 'Desc';
  protected DEFAULT_SORT_FIELD: string = 'updated';

  get currentMatSort(): Sort | undefined {
    const sort = this.currentSorting as any;
    if (!sort) return undefined;
    return {
      active: sort?.field,
      direction: sort?.direction.toLowerCase()
    };
  }

  // Sorting
  private currentSorting:
    | (typeof this.queryFunction extends (variables: { sorting: infer F }, ...args: any[]) => any ? F : never)
    | undefined = undefined;
  // Pagination
  private currentPage = 1;

  // Override this to increase the page limit
  private _pageLimit = 10;

  // Getters and setters for pagination
  get pageLimit() {
    return this._pageLimit;
  }
  set pageLimit(value) {
    this._pageLimit = value;
    this.refetchQuery();
  }

  // Forms
  private _createFormGroup!: FormGroup<{
    [K in keyof AbstractFormControl<
      typeof this.createOneFunction extends (variables: { input: infer U }) => Observable<MutationResult<any>>
        ? Unwrap<U>
        : never
    >]: ɵElement<
      AbstractFormControl<
        typeof this.createOneFunction extends (variables: { input: infer U }) => Observable<MutationResult<any>>
          ? Unwrap<U>
          : never
      >[K],
      null
    >;
  }>;
  /**
   * @deprecated use buildCreateFormGroup instead
   */
  get createFormGroup() {
    return this._createFormGroup;
  }

  private _updateFormGroup!: FormGroup<{
    [K in keyof AbstractFormControl<
      typeof this.updateOneFunction extends (variables: { input: infer U }) => Observable<MutationResult<any>>
        ? U
        : never
    >]: ɵElement<
      AbstractFormControl<
        typeof this.updateOneFunction extends (variables: { input: infer U }) => Observable<MutationResult<any>>
          ? U
          : never
      >[K],
      null
    >;
  }>;
  /**
   * @deprecated use buildUpdateormGroup instead
   */
  get updateFormGroup() {
    return this._updateFormGroup;
  }

  // Function references
  abstract queryFunction: T['queryFunction'];
  abstract queryOneFunction: T['queryOneFunction'];
  abstract updateOneFunction: T['updateOneFunction'];
  abstract deleteOneFunction: T['deleteOneFunction'];
  abstract createOneFunction: T['createOneFunction'];
  abstract updateManyFunction: T['updateManyFunction'];

  protected updateOneMessages: NotificationMessages = {
    successMessage: 'Erfolgreich aktualisiert',
    errorMessage: 'Fehler beim Aktualisieren'
  };
  protected deleteOneMessages: NotificationMessages = this.updateOneMessages;
  protected createOneMessages: NotificationMessages = this.updateOneMessages;
  protected updateManyMessages: NotificationMessages = this.updateOneMessages;

  constructor(
    protected txApi: API,
    protected formBuilder: FormBuilder,
    protected route?: ActivatedRoute,
    protected notificationService?: NotificationService
  ) {
    if (this.route?.snapshot.queryParamMap.has('page')) {
      this.currentPage = Number(this.route.snapshot.queryParamMap.get('page'));
    }
    if (this.route?.snapshot.queryParamMap.has('size')) {
      this._pageLimit = Number(this.route.snapshot.queryParamMap.get('size')); // dont set pageLimit directly, because it will refetch the query and service might not be initialized
    }
  }

  /**
   * Initializes the service. Needs to be called in the constructor of the child class
   */
  public init() {
    if (this.queryFunction === undefined) {
      throw new Error('queryFunction is not defined in init()');
    }

    this.currentSorting = {
      field: this.DEFAULT_SORT_FIELD,
      direction: this.DEFAULT_SORT_DIRECTION.toUpperCase() as any
    } as any;

    // Initialize the query
    this.dataQuery$ = this.queryFunction({
      sorting: this.getSorting(),
      filter: this.getFilter(),
      paging: this.getOffsetPaging()
    }) as ReturnType<T['queryFunction']>;

    // Subscribe to changed of the dataQuery
    this.dataQuery$?.valueChanges
      .pipe(
        tap(({ data }) => this.extractPageInfo(data)),
        map(({ data }) => this.mapQueryData(data)),
        map(cloneDeep)
      )
      .subscribe((data) => {
        // Push the data to the data$ behavior subject
        this.data$.next(data);
        this._dataSource.data = data;
      });
  }

  /**
   * Extracts the pageInfo from the query data
   *
   * @param data query data
   */
  private extractPageInfo(data: any) {
    // No data = no pageInfo
    if (!data) {
      return data;
    }

    // Get the keys of the data
    const keys = Object.keys(data);
    if (keys.length == 1) {
      // Get the pageInfo
      // Data always lookes like { queryDataResultKey: { pageInfo: PageInfo, ... } }
      // Where queryDataResultKey is the key of the query result and may differ
      const pageInfo = data[keys[0]].pageInfo;

      // If pageInfo is available set it
      if (pageInfo) {
        this.pageInfo$.next(pageInfo);

        // Check if pageInfo is correct or wrong pagination is used
        if (pageInfo.startCursor !== null && pageInfo.startCursor !== undefined) {
          throw new Error('Wrong pagination pageInfo. Use OffsetPagination instead of CursorPaginagtion');
        }
      }

      const totalCount = data[keys[0]].totalCount;
      if (totalCount == undefined) {
        console.error('Couldnt setup Pagination automatically. totalCount is not defined in the query');
      }
      this.totalCount$.next(totalCount);
    }

    return data;
  }

  /**
   * Refetches the query with the current sorting, filter and paging
   */
  private refetchQuery() {
    this.dataQuery$.refetch({
      sorting: this.getSorting(),
      filter: this.getFilter(),
      paging: this.getOffsetPaging()
    });
  }

  //#region Forms
  /**
   * Builds a form group for the create function
   *
   * @param controls controls for the form group
   * @param options options for the form group
   */
  public buildCreateFormGroup(
    controls: AbstractFormControl<
      typeof this.createOneFunction extends (variables: { input: infer U }) => Observable<MutationResult<any>>
        ? Unwrap<U>
        : never
    >,
    options?: AbstractControlOptions | null
  ) {
    const form = new NxFormGroup<
      typeof this.createOneFunction extends (variables: { input: infer U }) => Observable<MutationResult<any>>
        ? Unwrap<U>
        : never
    >(controls, options);
    this._createFormGroup = form as FormGroup;
    return form;
  }

  public buildUpdateFormGroup(
    controls: AbstractFormControl<
      typeof this.updateOneFunction extends (variables: { input: infer U }) => Observable<MutationResult<any>>
        ? U
        : never
    >,
    options?: AbstractControlOptions | null
  ) {
    const form = new NxFormGroup<
      typeof this.updateOneFunction extends (variables: { input: infer U }) => Observable<MutationResult<any>>
        ? U
        : never
    >(controls, options);
    this._updateFormGroup = form as FormGroup;
    return form;
  }

  public updateFormGroupSetValue(value: DataType[0]) {
    // Extract ID
    const id = value.id;
    this.updateFormGroup.patchValue({ id, update: value } as any);
  }
  //#endregion Forms

  //#region Pagination
  /**
   * Handle Angular Material PageEvent
   */
  public matPageChange(event: PageEvent) {
    this.currentPage = event.pageIndex + 1; // index starts at 0 but page starts at 1
    this.pageLimit = event.pageSize;

    this.refetchQuery();
  }

  /**
   * Fetches the next page if possible
   */
  public previousPage(refetch = true) {
    if (this.currentPage == 1 || !this.pageInfo?.hasPreviousPage) return;
    this.currentPage--;

    if (refetch) {
      this.refetchQuery();
    }
  }

  /**
   * Fetches the previous page if possible
   */
  public nextPage(refetch = true) {
    if (!this.pageInfo?.hasNextPage) return;
    this.currentPage++;

    if (refetch) {
      this.refetchQuery();
    }
  }

  /**
   * Gets the current offset paging
   */
  private getOffsetPaging(): OffsetPaging {
    return {
      limit: this._pageLimit,
      offset: this._pageLimit * (this.currentPage - 1)
    };
  }
  //#endregion Pagination

  //#region Sorting
  /**
   * Handle Angular Material SortEvent
   */
  public sortDataSource(sort: Sort) {
    // Check if the field is sortable
    if (this.sortableFields.indexOf(sort.active) === -1) {
      this.currentSorting = undefined;
      throw new Error(`Field ${sort.active} is not sortable`);
    }

    // Set the current sorting
    if (sort.direction === '') {
      this.currentSorting = undefined;
    } else {
      this.currentSorting = {
        field: sort.active,
        direction: sort.direction.toUpperCase() as SortDirection
      } as any;
    }

    // Refetch the query
    this.sort(this.currentSorting, true);
  }

  /**
   * Refetches the data with a new sorting
   *
   * @param sort sort direction and field
   */
  public sort(
    sort:
      | (typeof this.queryFunction extends (variables: { sorting: infer F }, ...args: any[]) => any ? F : never)
      | undefined,
    refetch = true
  ) {
    this.currentSorting = sort;

    if (refetch) {
      this.refetchQuery();
    }
  }

  /**
   * Gets the current sorting
   */
  private getSorting() {
    if (!this.currentSorting) return undefined;
    return {
      field: (this.currentSorting as any)?.field,
      direction: (this.currentSorting as any)?.direction.toUpperCase()
    };
  }
  //#endregion Sorting

  //#region Filter
  /**
   * Refetches the data with a new filter. If filter has type never the query does not
   * support filtering
   *
   * @param filter custom filter to apply
   * @returns
   */
  public filter(
    filter: typeof this.queryFunction extends (variables: { filter: infer F }, ...args: any[]) => QueryRef<any, any>
      ? F
      : never,
    refetch = true
  ) {
    this.currentFilter = filter as any;

    if (refetch) {
      this.refetchQuery();
    }
  }

  /**
   * search data with autmatic or
   *
   * SEARCH_FIELDS needs to be implemented in the child class
   */
  public search(search: string) {
    return this.dataQuery$.refetch({
      filter: this.getFilter(search),
      sorting: this.getSorting()
    });
  }

  /**
   * Gets the filter for the query
   */
  private getFilter(search?: string) {
    if (!search) return this.currentFilter;
    if (this.SEARCH_FIELDS.length === 0) return {};

    const filter = {
      or: this.SEARCH_FIELDS.map((s) => {
        return { [s]: { iLike: `%${search}%` } };
      })
    };
    this.currentFilter = filter as typeof this.queryFunction extends (
      variables: {
        filter: infer F;
      },
      ...args: any[]
    ) => QueryRef<any, any>
      ? F
      : never;
    return filter;
  }
  //#endregion Filter

  /**
   * Maps the data from the query to the data type.
   * needs to be implemented in the child class
   *
   * @example
   *
   * mapData(data: DcamQuery) {
   *  return data.dcams[0];
   * }
   *
   * @param data
   */
  protected abstract mapQueryData(data: any): DataType;

  //#region Getters
  // Common getter functions
  /**
   * This is the main observable to subscribe to. Dont use the BehaviorSubject directly (its private anyways)
   */
  public get dataObservable$(): Observable<DataType | undefined> {
    return this.data$;
  }

  /**
   * Get the plain data array
   */
  public get data(): DataType | undefined {
    return this.data$.value;
  }

  /**
   * Get the first element of the data
   */
  public get firstData(): DataType[0] | undefined {
    return this.data$.value ? this.data$.value[0] : undefined;
  }

  /**
   * Get the first element of the data as an observable
   */
  public get dataObservableSingle$(): Observable<DataType[0]> {
    return this.data$.pipe(map((data) => data?.[0]));
  }

  /**
   * Get the data as a observable
   */
  public get pageInfoObservable$(): Observable<OffsetPageInfo | undefined> {
    return this.pageInfo$;
  }

  /**
   * Get the pageInfo
   */
  public get pageInfo(): OffsetPageInfo | undefined {
    return this.pageInfo$.value;
  }

  public get totalCountObservable$(): Observable<number | undefined> {
    return this.totalCount$;
  }

  public get totalCount(): number | undefined {
    return this.totalCount$.value;
  }
  //#endregion Getters

  protected showError(error: any, title: string, message: string): never {
    this.notificationService?.showError(title, message);
    throw error;
  }

  protected showSuccess(title: string, message: string) {
    this.notificationService?.showSuccess(title, message);
  }

  //#region Query
  /**
   * Finds one specific object by id.
   * This will create a new Observable every time be sure to clear your subscribers!
   *
   * @param id Identifier of the object to find
   * @returns Observable
   */
  public findOne(id: string) {
    // Check if the queryOneFunction is defined
    if (!this.queryOneFunction) {
      throw Error("queryOneFunction is not defined. Can't find one");
    }

    // Query the object
    const findOneQuery$ = this.queryOneFunction({ id });

    // Return the observable
    return findOneQuery$.valueChanges.pipe(
      map(({ data }) => {
        // Check if data is set
        if (!data) {
          console.error('No data found');
          return;
        }
        if (!Object.keys(data).length) {
          console.error('No data found');
          return;
        }
        if (!data[Object.keys(data)[0]]) {
          console.error('No data found');
          return;
        }
        // Return the data 1 level deep (first level is always the name of the query)
        return data[Object.keys(data)[0]];
      })
    ) as Observable<DataType[0]>;
  }
  //#endregion

  //#region CRUD
  /**
   * Updates one object
   *
   * Types are infered of the function you pass to updateOneFunction
   *
   * @param id id of the object to update
   * @param data data to update
   * @returns update result
   */
  public updateOne(
    input: typeof this.updateOneFunction extends (
      variables: { input: infer U },
      ...args: any[]
    ) => Observable<MutationResult<any>>
      ? DeepPartial<U>
      : never,
    options: { successMessage: string; errorMessage: string } = this.updateOneMessages
  ): ReturnType<NonNullable<T['updateOneFunction']>> {
    if (this.updateOneFunction === undefined) {
      throw new Error('updateOneFunction is not defined');
    }
    return this.handleResult(this.updateOneFunction({ input }), options) as ReturnType<
      NonNullable<T['updateOneFunction']>
    >;
  }

  /**
   * Deletes one object with id
   *
   * Idprefix is used to evict the cache if prefix = "DcamContact" and id = "1" the cache id is "DcamContact:1"
   *
   * @param id the id of the object to delete
   * @param idPrefix the Prefix of the cache id
   * @returns delete result
   */
  public deleteOne(
    id: string,
    idPrefix: string,
    options: { successMessage: string; errorMessage: string } = this.deleteOneMessages
  ): ReturnType<NonNullable<T['deleteOneFunction']>> {
    if (this.deleteOneFunction === undefined) {
      throw new Error('deleteOneFunction is not defined');
    }
    return this.handleResult(
      this.deleteOneFunction(
        { input: { id } },
        {
          update: (cache, { data }) => {
            if (data) {
              cache.evict({ id: `${idPrefix}:${id}` });
              cache.gc();
            }
          }
        }
      ),
      options
    ) as ReturnType<NonNullable<T['deleteOneFunction']>>;
  }

  /**
   * Creates one object
   *
   * @param data the data to update
   * @returns create result
   */
  public createOne(
    input: typeof this.createOneFunction extends (
      variables: { input: infer U },
      ...args: any[]
    ) => Observable<MutationResult<any>>
      ? DeepPartial<U>
      : never,
    options: { successMessage: string; errorMessage: string } = this.createOneMessages
  ): ReturnType<NonNullable<T['createOneFunction']>> {
    // Not correct implemented extension of base service
    if (this.createOneFunction === undefined) {
      throw new Error('createOneFunction is not defined');
    }

    return this.handleResult(this.createOneFunction({ input }), options) as ReturnType<
      NonNullable<T['createOneFunction']>
    >;
  }

  /**
   * Pipes the result of a mutation and shows a success or error message
   *
   * @example
   *
   * return this.handleResult(this.updateOneFunction({ input: { id, update: data } }), {
   *  successMessage: 'Erfolgreich gespeichert',
   *  errorMessage: 'Fehler beim Speichern'
   * })
   *
   * @param result request result
   * @param options success and error message
   * @returns result
   */
  protected handleResult(
    result: Observable<MutationResult<any>>,
    options: { successMessage: string; errorMessage: string }
  ) {
    return result.pipe(
      map((response) => {
        if (response.data) {
          this.showSuccess('Erfolg', options.successMessage);
        }
        return response;
      }),
      catchError((error) => {
        return this.showError(error, 'Fehler', options.errorMessage);
      }),
      tap(() => this.refetchQuery())
    );
  }
  //#endregion CRUD
}
