import { Pipe, PipeTransform } from '@angular/core';
import { Icon } from '@n/nui';

@Pipe({
  name: 'serviceIcon'
})
export class ServiceIconPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): Icon {
    switch (value) {
      case 'vra':
        return 'cloud';
      case 's3':
        return 'bucket';
      case 'vpc':
        return 'cloud-arrow-up'; 
      case  'opencloud':
        return 'globe-alt'
      default:
        return 'squares-2x2';
    }
  }

}
