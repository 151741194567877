<div class="flex items-center px-6 py-4 bg-gray-100 rounded-xl">
  <div (click)="contextMenuClick.emit('detail')" class="hover:cursor-pointer w-1/2">
    <span class="block font-semibold">{{ user.firstName }} {{ user.lastName }}</span>
    <span class="block text-xs text-gray-500">{{ user.email }}</span>
  </div>
  <div class="w-1/2">
    <ng-content></ng-content>
  </div>
  <div class="shrink-0">
    <ui-context-menu
      *ngIf="!disabled"
      [contextMenu]="contextMenu"
      (contextMenuClick)="contextMenuClick.emit($event)"
    ></ui-context-menu>
  </div>
</div>
