import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Maybe } from 'graphql/jsutils/Maybe';
import { map, Observable, switchMap } from 'rxjs';
import { Gender, Person, ServiceInstance } from '@tx/api';
import { ContextMenuItem, Option, TxTab } from '@tx/ui';
import { CreateAndAddServiceUser, PersonAsServiceUser, RoledPerson, UpdateServiceUser } from '../../shared/models';

@Component({
  selector: 'tx-portal-service-user-partial',
  templateUrl: './service-user-partial.component.html'
  /*animations: [
    trigger('tabChange', [
      state('void', style({ left: '-2rem', position: 'relative', opacity: '0', height: '0px' })),
      state('*', style({ left: '0', position: 'relative', opacity: '1' })),
      transition('void <=> *', animate('100ms linear'))
    ])
  ]*/
})
export class ServiceUserPartialComponent implements OnInit {
  genderOptions: Array<string> = ['Männlich', 'Weiblich', 'Divers'];
  newPersonForm = this.fb.group({
    firstName: [''],
    lastName: [''],
    email: ['', [Validators.required, Validators.email]],
    role: this.fb.nonNullable.control('', Validators.required)
  });
  editPersonForm = this.fb.group({
    id: this.fb.control({ value: '', disabled: true }),
    firstName: this.fb.control({ value: '', disabled: true }),
    lastName: this.fb.control({ value: '', disabled: true }),
    email: this.fb.control({ value: '', disabled: true }),
    role: this.fb.control('')
  });

  tabs: TxTab[] = [
    { value: 'Bestehenden Account', icon: 'user' },
    { value: 'Neuen Account erstellen', icon: 'plus' }
  ];

  modalActiveTab = this.tabs[0].value;

  switchTab(tab: string) {
    this.modalActiveTab = tab;
  }

  selectedPerson: Option | null = null;

  selectPerson(person: Option) {
    this.selectedPerson = person;
  }

  saveAndClose(serviceInstance: ServiceInstance, people: Person[]) {
    const existingUser = people.find((u) => u.id === this.selectedPerson?.value);
    if (existingUser) {
      let serviceUserGroups = existingUser.serviceUserGroups || { data: {} };

      serviceUserGroups = structuredClone(serviceUserGroups);
      delete serviceUserGroups.__typename;
      if (this.modalActiveTab === this.tabs[0].value && this.selectedPerson) {
        serviceUserGroups.data[serviceInstance.id] = this.newPersonForm.value.role || 'User';
        // option existing user
        this.addServiceUser.emit({
          id: this.selectedPerson.value,
          serviceInstanceId: serviceInstance.id,
          serviceUserGroups: serviceUserGroups
        });
      }
    } else {
      // option new user
      this.createServiceUser.emit({
        person: this.newPerson,
        serviceInstanceId: serviceInstance.id,
        serviceUserGroups: {
          data: {
            [serviceInstance.id]: this.newPersonForm.value.role || 'User'
          }
        }
      });
    }
    this.clearEditPerson();
  }

  @Input() serviceInstance$!: Observable<ServiceInstance>;
  @Input() public disabled!: boolean | null | undefined;
  @Input() people$!: Observable<Person[]>;

  @Output()
  addServiceUser: EventEmitter<PersonAsServiceUser> = new EventEmitter();

  @Output()
  removeServiceUser: EventEmitter<PersonAsServiceUser> = new EventEmitter();

  @Output()
  createServiceUser: EventEmitter<CreateAndAddServiceUser> = new EventEmitter();

  @Output()
  updateServiceUser: EventEmitter<UpdateServiceUser> = new EventEmitter();

  @Input() newServiceUserModal = false;
  @Output() newServiceUserModalToggle = new EventEmitter<null>();

  newPerson: RoledPerson = {
    firstName: '',
    lastName: '',
    email: '',
    role: 'Manager'
  } as RoledPerson;
  editPerson: RoledPerson = {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    serviceUserGroups: {},
    role: 'Manager'
  } as RoledPerson;

  peopleOptions$!: Observable<Option[]>;

  activeTab = 'Service User';

  editOwnerModal = false;

  contextMenu: Array<ContextMenuItem[]> = [
    [
      { label: 'Detail', icon: 'document-text', event: 'detail' },
      { label: 'Entfernen', icon: 'v-trash', event: 'delete', isDanger: true }
    ]
  ];

  contextMenuClick(event: string, user: Person, serviceInstance: ServiceInstance) {
    switch (event) {
      case 'detail':
        this.fillEditPerson(user as RoledPerson, serviceInstance);
        this.editOwnerModal = true;
        return;
      case 'delete':
        delete user.serviceUserGroups?.data[serviceInstance.id];
        if (!user.serviceUserGroups) {
          user.serviceUserGroups = {
            data: {}
          };
        }
        delete user.serviceUserGroups.__typename;
        this.removeServiceUser.emit({
          id: user.id,
          serviceInstanceId: serviceInstance.id,
          serviceUserGroups: user.serviceUserGroups
        });
        return;
      default:
        break;
    }
  }

  getGenderEnum(gender: string | null | undefined): Gender {
    switch (gender) {
      case 'Männlich':
        return Gender.M;
      case 'Weiblich':
        return Gender.W;
      case 'Divers':
        return Gender.D;
    }
    return Gender.D;
  }

  getGenderString(gender: Maybe<Gender> | undefined): string {
    switch (gender) {
      case Gender.M:
        return 'Männlich';
      case Gender.W:
        return 'Weiblich';
      case Gender.D:
        return 'Divers';
    }
    return '';
  }

  fillEditPerson(person: RoledPerson, serviceInstance: ServiceInstance) {
    this.editPersonForm.setValue({
      id: person.id,
      firstName: person.firstName ? person.firstName : '',
      lastName: person.lastName ? person.lastName : '',
      email: person.email ? person.email : '',
      role: person.serviceUserGroups?.data[serviceInstance.id] || ''
    });
    this.editPerson.id = person.id;
    delete person.serviceUserGroups?.__typename;
    this.editPerson.serviceUserGroups = person.serviceUserGroups;
  }

  clearEditPerson() {
    this.editPersonForm.reset();
    this.newPersonForm.reset();
    this.selectedPerson = null;
  }

  constructor(private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.peopleOptions$ = this.people$.pipe(
      switchMap((p) =>
        this.serviceInstance$.pipe(
          map((serviceInstance) =>
            p
              .filter(
                (person) =>
                  !serviceInstance.serviceUsers?.some((serviceInstancePerson) => serviceInstancePerson.id == person.id)
              )
              .map((p2) => {
                return {
                  value: p2.id,
                  label: `${p2.email}`,
                  description: `${p2.firstName} ${p2.lastName}`
                };
              })
          )
        )
      )
    );
    this.newPersonForm.valueChanges.subscribe((form) => {
      this.newPerson = {
        firstName: form.firstName ? form.firstName : '',
        lastName: form.lastName ? form.lastName : '',
        email: form.email ? form.email : '',
        role: form.role ? form.role : 'User'
      } as RoledPerson;
    });
    this.editPersonForm.valueChanges.subscribe((form) => {
      this.editPerson.role = form.role || 'User';
    });
  }
}
