import { Injectable } from '@angular/core';
import {
  Person,
  ServiceInstance,
  ServiceInstanceSort,
  ServiceInstanceSortFields,
  ServiceInstancesQuery,
  ServiceInstancesQueryVariables,
  SortDirection,
  TxApi
} from '@tx/api';
import { QueryRef } from 'apollo-angular/query-ref';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Sort } from '@angular/material/sort';
import { cloneDeep } from '@apollo/client/utilities';
import { NotificationService } from '../../../../../libs/ui/src/lib/notification/notification.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {
  serviceInstancesQuery!: QueryRef<ServiceInstancesQuery, ServiceInstancesQueryVariables>;

  serviceInstances$!: Observable<ServiceInstance[]>;

  defaultSorting: ServiceInstanceSort = {
    direction: SortDirection.Asc,
    field: ServiceInstanceSortFields.StartDate
  };

  constructor(private txApi: TxApi, private notificationService: NotificationService) {
    this.serviceInstancesQuery = this.txApi.serviceInstancesWatch();

    this.serviceInstances$ = this.serviceInstancesQuery.valueChanges.pipe(
      map(({ data, loading }) => {
        return data.serviceInstances.edges.map((n) => n.node) as ServiceInstance[];
      }),
      map(cloneDeep)
    );
  }

  _latestSort?: Sort;

  _searchFields = ['sinr', 'subServiceText', 'serviceName', 'details'];

  async sort(sort: Sort) {
    if (sort.direction == '') {
      this.serviceInstancesQuery.refetch({
        sorting: this.defaultSorting
      });
      return;
    }
    this.serviceInstancesQuery.refetch({
      sorting: {
        field: sort.active,
        direction: sort.direction.toUpperCase()
      } as ServiceInstanceSort
    });
  }

  async search(search: string) {
    let variables;
    if (search === '') {
      variables = {
        sorting: this.defaultSorting,
        filter: {}
      };
    } else {
      variables = {
        sorting: this.defaultSorting,
        filter: {
          or: this._searchFields.map((s) => {
            return { [s]: { iLike: `%${search}%` } };
          })
        }
      };
    }
    if (this._latestSort) {
      variables = {
        ...variables,
        sorting: {
          field: this._latestSort.active,
          direction: this._latestSort.direction.toUpperCase()
        } as ServiceInstanceSort
      };
    }

    this.serviceInstancesQuery.refetch(variables);
  }

  updateServiceInstance(
    event: Pick<ServiceInstance, 'id' | 'serviceOwnerIsContactPerson' | 'serviceOwnerIsRequestContactPerson'>
  ) {
    this.txApi
      .updateOneServiceInstance({
        input: {
          id: event.id,
          update: event
        }
      })
      .subscribe();
  }

  serviceInstance$(serviceInstanceId: string): Observable<ServiceInstance> {
    return this.serviceInstances$.pipe(
      map((instances) => instances.find((i) => i.id === serviceInstanceId) as ServiceInstance)
    );
  }

  serviceOwners$(serviceInstanceId: string): Observable<Person[]> {
    return this.serviceInstance$(serviceInstanceId).pipe(
      map((serviceInstance) => {
        return serviceInstance.serviceOwners;
      })
    );
  }
}
