import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function cidrValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value: string = control.value;

    // Regex für CIDR-Notation (IPv4)
    const cidrRegex = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}\/([0-9]|[1-2][0-9]|3[0-2])$/;

    if (!value) {
      return null; // Kein Fehler, wenn das Feld leer ist (kann mit `Validators.required` kombiniert werden)
    }

    if (!cidrRegex.test(value)) {
      return { cidrInvalid: true }; // Fehler, wenn das Format nicht passt
    }

    // Zusätzliche Validierung: Prüfe, ob die IP gültig ist
    const [ip, prefix] = value.split('/');
    const ipParts = ip.split('.').map(Number);

    const isValidIp = ipParts.every((part) => part >= 0 && part <= 255);

    return isValidIp ? null : { cidrInvalid: true };
  };
}
