import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NotificationService, Option } from '@tx/ui';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { PartyService } from '../../../party/party.service';
import { ServiceService } from '../../service.service';
import { ObjectStorageService } from '@tx/s3';
import { Router } from '@angular/router';
@Component({
  selector: 'tx-portal-s3-new-project-modal',
  templateUrl: './new-project-modal.component.html'
})
export class S3NewProjectModal {
  @Input() isS3InitModalOpen!: boolean;
  @Output() closeModal = new EventEmitter<boolean>();

  isS3InitLoading = false;

  initS3Form = this.fb.group({
    invoiceRecipient: '',
    s3: [false, Validators.requiredTrue],
    policy: [false, Validators.requiredTrue]
  });

  constructor(
    private fb: FormBuilder,
    private partyService: PartyService,
    private notificationService: NotificationService,
    private serviceService: ServiceService,
    private objectStorageService: ObjectStorageService,
    private router: Router
  ) {
    this.orgOptions$ = this.partyService.organisations$.pipe(
      map((data) => {
        let orgs = data.map((o) => {
          return {
            label: `${o.name}`,
            description: `${o.customerNumbers?.[0]}`,
            value: o.id
          } as Option;
        });
        this._selectedOrgOption$.next(orgs[0]);

        return orgs;
      })
    );
  }

  orgOptions$!: Observable<Option[]>;
  _selectedOrgOption$ = new BehaviorSubject<Option | null>(null);

  createS3Tenant() {
    this.isS3InitLoading = true;
    this.partyService.organisations$.subscribe(async (orgs) => {
      try {
        const service = await this.objectStorageService.buyS3({
          ...this.initS3Form.value,
          orgId: orgs[0].id
        });

        await this.serviceService.serviceInstancesQuery.refetch();
        this.router.navigate([`/services/detail/${service.id}`]);
      } catch (e: any) {
        this.isS3InitLoading = false;
        if (e.error?.statusCode === 409) {
          this.notificationService.showError('S3 Error', 'Sie können S3 nur einmal pro Kundennummer buchen');
        } else {
          this.notificationService.showError('S3 Error', 'Es ist ein Fehler aufgetreten');
        }
      }
    });
  }
}
