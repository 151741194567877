<div *ngIf="serviceInstance$ | async as _serviceInstance">
  <ui-modal
    *ngIf="people$ | async as _people"
    [isModalOpen]="newServiceUserModal"
    [title]="'Service User hinzufügen'"
    [submitLabel]="'Speichern & Hinzufügen'"
    (onSubmit)="saveAndClose(_serviceInstance, _people)"
    (onClose)="newServiceUserModalToggle.emit(); clearEditPerson()"
  >
    <div class="flex w-full justify-center my-8">
      <div class="bg-gray-100 p-1 rounded-2xl">
        <ui-tabs
          *ngIf="tabs"
          [objTabs]="tabs"
          (activeTabChange)="switchTab($event)"
          [activeTab]="modalActiveTab"
        ></ui-tabs>
      </div>
    </div>

    <ng-container *ngIf="modalActiveTab === tabs[0].value">
      <ui-forms-combobox
        *ngIf="peopleOptions$ | async as _options"
        name="users"
        label="Bestehende Person hinzufügen"
        [initalValue]="false"
        [options]="_options"
        [selectedValue]="selectedPerson"
        (cchange)="selectPerson($event)"
      >
      </ui-forms-combobox>
    </ng-container>
    <ng-container *ngIf="modalActiveTab === tabs[1].value">
      <ui-info class="mb-6">
        <ui-icon [icon]="'information-circle'" class="w-5 h-5 shrink-0"></ui-icon>
        <p>
          Die Person wird als neuer TelemaxX Kundencenter-Benutzer eingeladen und erhält die zugewiesene Rolle. Achten
          Sie bitte auf die korrekte E-Mail Adresse.
        </p>
      </ui-info>
      <ui-form-wrapper [parentForm]="newPersonForm">
        <div class="grid grid-cols-2 gap-4">
          <ui-forms-input
            label="Vorname"
            icon="user"
            [controlName]="'firstName'"
            [parentForm]="newPersonForm"
          ></ui-forms-input>
          <ui-forms-input
            label="Nachname"
            icon="user"
            [controlName]="'lastName'"
            [parentForm]="newPersonForm"
          ></ui-forms-input>
          <ui-forms-input
            class="col-span-2"
            label="E-Mail"
            icon="envelope"
            [type]="'email'"
            [controlName]="'email'"
            [parentForm]="newPersonForm"
          ></ui-forms-input>
        </div>
      </ui-form-wrapper>
    </ng-container>
    <ui-forms-select
      class="mt-4"
      label="Rolle"
      name="role"
      [placeholder]="'Rolle wählen'"
      [options]="['Manager', 'User', 'Supporter']"
      [controlName]="'role'"
      [parentForm]="newPersonForm"
    ></ui-forms-select>

    <div class="flex items-start relative mt-4">
      <div class="group">
        <div class="text-sm font-medium flex items-center gap-1 text-blue-500">
          <ui-icon [icon]="'question-mark-circle'" class="h-5 w-5"></ui-icon>
          <span>Was bedeutet die Rolle?</span>
        </div>
        <div class="absolute left-0 bottom-0 pb-7 hidden z-50 group-hover:block w-full">
          <ui-info class="ring-2 ring-blue-500 ring-offset-0 rounded-xl">
            <div class="flex flex-col">
              <p>
                <span class="font-medium">Rolle Cloud User: Standard-Rolle<br /></span>
                Ein Benutzer mit der Rolle Cloud-User hat Zugriff:
              </p>
              <ul class="list-disc pl-4">
                <li>auf die Projekte, denen die Gruppe "All-Users-..." zugewiesen ist</li>
                <li>auf die Projekte (VMs & Templates), denen er als User explizit zugewiesen ist</li>
              </ul>
              <p>
                Alle Benutzer mit der Rolle Cloud-User werden automatisch in die Gruppe "All-Users-..." zusammengefasst.
                Nutzer mit dieser Rolle haben keinen Zugriff auf die Projekt-Management Funktionen.<br />
                <br />
                <span class="font-medium">Rolle Cloud Manager: Erweiterte Rolle<br /></span>
                Ein Benutzer mit der Rolle Cloud-Manager hat Zugriff:
              </p>
              <ul class="list-disc pl-4">
                <li>auf alle Projekte (VMs & Templates)</li>
                <li>kann alle verfügbare Aktionen auf VMs ausführen</li>
                <li>zusätzlich Zugriff auf die Projekt-Management Funktionen</li>
              </ul>
              <p>
                Alle Benutzer mit der Rolle Cloud-User werden automatisch in die Gruppe "All-Managers-..."
                zusammengefasst.
              </p>
              <a class="underline" target="_blank" href="https://kb.telemaxx.de/docs/cloud-user-roles-projects"
                >Mehr erfahren</a
              >
            </div>
          </ui-info>
        </div>
      </div>
    </div>
  </ui-modal>

  <ui-modal
    [isModalOpen]="editOwnerModal"
    [title]="'Service User Editieren'"
    (onClose)="editOwnerModal = false; clearEditPerson()"
    (onSubmit)="
      updateServiceUser.emit({
        person: editPerson,
        serviceInstanceId: _serviceInstance.id
      });
      editOwnerModal = false;
      clearEditPerson()
    "
  >
    <ui-form-wrapper [parentForm]="editPersonForm">
      <div class="grid grid-cols-2 gap-4">
        <ui-forms-input
          label="Vorname"
          icon="user"
          [controlName]="'firstName'"
          [parentForm]="editPersonForm"
        ></ui-forms-input>
        <ui-forms-input
          label="Nachname"
          icon="user"
          [controlName]="'lastName'"
          [parentForm]="editPersonForm"
        ></ui-forms-input>
        <ui-forms-input
          label="Firmenname"
          icon="cloud"
          [controlName]="'companyName'"
          [parentForm]="editPersonForm"
        ></ui-forms-input>
        <ui-forms-input
          label="Telefon"
          icon="phone"
          [controlName]="'phone'"
          [parentForm]="editPersonForm"
        ></ui-forms-input>
        <ui-forms-input
          label="Mobile"
          icon="phone"
          [controlName]="'mobilePhone'"
          [parentForm]="editPersonForm"
        ></ui-forms-input>
        <ui-forms-input
          class="col-span-2"
          label="E-Mail"
          icon="envelope"
          [type]="'email'"
          [controlName]="'email'"
          [parentForm]="editPersonForm"
        ></ui-forms-input>
        <ui-forms-select
          class="mt-4"
          label="Rolle"
          name="role"
          [placeholder]="'Rolle wählen'"
          [options]="['Manager', 'User', 'Supporter']"
          [controlName]="'role'"
          [parentForm]="editPersonForm"
        ></ui-forms-select>
      </div>
    </ui-form-wrapper>
  </ui-modal>

  <ui-headline
    heading="Service User & Access"
    subheading="Diese Nutzer haben Zugang zum Service"
    size="lg"
    class="mb-8"
  ></ui-headline>

  <div class="flex flex-col gap-y-4">
    <tx-portal-user-card
      [user]="user"
      [disabled]="!!disabled"
      *ngFor="let user of _serviceInstance.serviceUsers"
      [contextMenu]="contextMenu"
      (contextMenuClick)="contextMenuClick($event, user, _serviceInstance)"
    >
      <ng-container *ngIf="user.serviceUserGroups && user.serviceUserGroups.data[_serviceInstance.id]">
        <ui-tag [label]="'Gruppe'">{{ user.serviceUserGroups.data[_serviceInstance.id] }}</ui-tag>
      </ng-container>
    </tx-portal-user-card>

    <span class="block w-full text-sm text-center" *ngIf="_serviceInstance.serviceUsers?.length === 0"
      >Noch kein Service User konfiguriert. <br />
      <a
        target="_blank"
        class="leading-6 text-goblin-500 hover:underline"
        href="https://kb.telemaxx.de/docs/kundencenter-benutzer-verwalten#howto-service-user-hinzuf%C3%BCgenentfernen---am-service"
      >
        Hilfe/Anleitung öffnen
      </a>
    </span>
    <hr />

    <ui-button
      color="gray"
      (cclick)="newServiceUserModalToggle.emit()"
      [isDisabled]="!!disabled"
      icon="plus-small"
      [isFullWidth]="true"
    >
      Service User hinzufügen
    </ui-button>
  </div>
</div>
