import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import {
  CreateServiceUserInput,
  InlinePerson,
  InlineProject,
  OpencloudProjectsQuery,
  ServiceUser,
  ServiceUserInput,
  ServiceUserRole,
  UpdateOneOpencloudProjectInput
} from '@tx/api';
import { ContextMenuItem, Option, TxTab } from '@tx/ui';
import { OpencloudProjectService, OpencloudProjectType } from '../../services/opencloud/opencloud-project.service';
import { NxFormGroup, SimpleFormControls } from '@tx/shared';
import * as uuid from 'uuid';

type ServiceUserResult = OpencloudProjectsQuery['opencloudProjects']['nodes'][0]['serviceUsers'][0];

@Component({
  selector: 'tx-portal-service-user-select',
  templateUrl: './service-user-select.component.html'
})
export class ServiceUserSelectComponent {
  @Output()
  serviceUserFormSubmit = new EventEmitter<void>();

  @Output()
  serviceUserFormDelete = new EventEmitter<string>();

  @Input() peopleOptions$!: Observable<Option[]>;
  @Input() serviceUsers!: ServiceUserResult[];
  @Input() project!: OpencloudProjectType;

  @Input()
  parentForm!: NxFormGroup<UpdateOneOpencloudProjectInput>;

  roleOptions: string[] = Object.values(ServiceUserRole);

  serviceUserForm = new NxFormGroup<CreateServiceUserInput>({
    id: this.fb.nonNullable.control(''),
    person: this.fb.group<SimpleFormControls<InlinePerson>>({
      id: this.fb.nonNullable.control(''),
      email: this.fb.nonNullable.control(''),
      firstName: this.fb.control(''),
      lastName: this.fb.control('')
    }),
    project: this.fb.group<SimpleFormControls<InlineProject>>({
      id: this.fb.nonNullable.control('')
    }),
    role: this.fb.nonNullable.control(ServiceUserRole.Admin)
  });

  tabs: TxTab[] = [
    { value: 'Bestehenden Account', icon: 'user' },
    { value: 'Neuen Account erstellen', icon: 'plus' }
  ];
  modalActiveTab = this.tabs[0].value;

  isServiceUserModalOpen: boolean = false;
  detailUserID: string | null = null;
  isDetailView: boolean = false;

  selectedPerson: Option | null = null;

  contextMenu: Array<ContextMenuItem[]> = [
    [
      { label: 'Detail', icon: 'information-circle', event: 'detail' },
      { label: 'Entfernen', icon: 'user-minus', event: 'delete', isDanger: true }
    ]
  ];

  constructor(private fb: FormBuilder, private projectService: OpencloudProjectService) {}

  // tabs
  switchTab(tab: string) {
    this.modalActiveTab = tab;
  }

  // modals
  openServiceUserModal() {
    this.isServiceUserModalOpen = true;
  }

  closeServiceUserModal() {
    this.serviceUserForm.reset();
    console.log('close');

    this.isServiceUserModalOpen = false;
  }

  selectPerson(person: Option) {
    this.selectedPerson = person;
  }

  submitServiceUser() {
    if (this.selectedPerson) {
      const serviceUser: CreateServiceUserInput = {
        id: uuid.v4(),
        person: {
          id: this.selectedPerson.value,
          email: this.selectedPerson.label
        },
        project: {
          id: this.project.id
        },
        role: this.serviceUserForm.value.role
      };
      this.serviceUserForm.setValue(serviceUser);
    } else {
      this.serviceUserForm.setValue({
        id: uuid.v4(),
        person: {
          id: uuid.v4(),
          email: this.serviceUserForm.value.person.email,
          firstName: this.serviceUserForm.value.person.firstName,
          lastName: this.serviceUserForm.value.person.lastName
        },
        project: {
          id: this.project.id
        },
        role: this.serviceUserForm.value.role
      });
    }
    if (!this.serviceUserForm.valid) return;

    this.parentForm.addChildElementToArray('update.serviceUsers', this.serviceUserForm);
    this.serviceUserFormSubmit.emit();
  }

  fillFormWithUser(user: ServiceUserResult) {
    this.serviceUserForm.setValue(user);
  }

  contextMenuClick(event: string, user: ServiceUserResult) {
    switch (event) {
      case 'detail':
        this.fillFormWithUser(user);
        this.modalActiveTab = this.tabs[1].value;
        this.detailUserID = user.id;
        this.isServiceUserModalOpen = true;
        return;
      case 'delete':
        //this.parentForm.removeChildElementByID('update.serviceUsers', user.id);
        this.serviceUserFormDelete.emit(user.id);
        return;
      default:
        break;
    }
  }
}
