<ui-modal
  [isModalOpen]="isS3InitModalOpen"
  [showButtons]="false"
  (onClose)="closeModal.emit()"
  title="TelemaxX S3 Service aktivieren"
>
  <p>
    Der TelemaxX S3 Service ist ein kostengünstiger, flexibel skalierbarer, hoch performanter Object Storage und bietet
    höchste Verfügbarkeit.
  </p>
  <ng-container *ngIf="!isS3InitLoading">
    <hr class="border-gray-200 my-4" />
    <div class="flex items-center justify-between text-lg my-8">
      <div class="font-bold flex">
        Pay-Per-Use
        <ui-icon
          [icon]="'information-circle'"
          title="Bei den aufgezählten Preisen handelt es sich um monatliche netto Einzelpreise (Stückpreise). Der TelemaxX S3
      Service wird im Pay-per-Use-Modell (Pay-per-Use-Modell: Nutzungsabhängiges Preismodell, bei welchem der Kunde
      ein Entgelt auf Basis seiner Nutzungsintensität entrichtet) betrieben."
          class="ml-2 mt-1 w-5 h-5 shrink-0 hover:cursor-pointer"
        ></ui-icon>
      </div>

      <span><span class="text-klee-100 text-lg font-bold">0,023 €</span> / GB pro Monat (Abrechnung in TB)</span>
    </div>

    <ng-container *ngIf="this.orgOptions$ | async as _orgOptions">
      <div *ngIf="_orgOptions.length > 1">
        <hr class="border-gray-200 my-4" />
        <ui-forms-custom-select
          [label]="'Organisation'"
          [selectedValue]="_selectedOrgOption$.getValue()"
          [options]="_orgOptions"
          (cchange)="this._selectedOrgOption$.next($event)"
        >
        </ui-forms-custom-select>
      </div>
    </ng-container>
    <hr class="border-gray-200 my-4" />
    <div class="flex flex-col gap-2 mb-4">
      <ui-forms-checkbox [id]="'s3'" [parentForm]="initS3Form" [controlName]="'s3'">
        <a
          href="https://www.telemaxx.de/fileadmin/docs/cloud/Service_Description_S3.pdf"
          target="_blank"
          class="text-klee-100 hover:underline"
          >Service Description S3</a
        >,
        <a
          href="https://www.telemaxx.de/fileadmin/docs/cloud/Service_Level_Agreement_S3.pdf"
          target="_blank"
          class="text-klee-100 hover:underline"
          >Service Level Agreement S3, </a
        >Preisinformation und Fair-Use-Policy gelesen
      </ui-forms-checkbox>

      <ui-forms-checkbox [id]="'policy'" [parentForm]="initS3Form" [controlName]="'policy'">
        <a
          href="https://www.telemaxx.de/fileadmin/docs/agb_sla_tom/AGB_TelemaxX_V4.0.pdf"
          target="_blank"
          class="text-klee-100 hover:underline"
          >AGB</a
        >
        und
        <a href="https://www.telemaxx.de/datenschutz-allgemein" target="_blank" class="text-klee-100 hover:underline"
          >Datenschutzerklärung</a
        >
        gelesen
      </ui-forms-checkbox>
    </div>
    <ui-button
      [isFullWidth]="true"
      [icon]="'squares-plus'"
      [color]="'goblin'"
      [isDisabled]="isS3InitLoading || !initS3Form.valid"
      (cclick)="createS3Tenant()"
      >S3 Storage kostenpflichtig aktivieren</ui-button
    >
  </ng-container>
  <ng-container *ngIf="isS3InitLoading">
    <div class="w-full aspect-2 flex flex-col items-center justify-center">
      <ui-icon [icon]="'minus-circle'" class="animate-spin w-8 h-8 block"></ui-icon>
      <div class="font-semibold text-center mt-4">S3 wird aktiviert</div>
    </div>
  </ng-container>
</ui-modal>
